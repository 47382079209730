import React, {useEffect} from "react";
import AboutTop from "./about/AboutTop.js";
import AboutSecondSection from "./about/AboutSecondSection.js";
import AboutTeamSection from "./about/AboutTeamSection.js";
import HomepageContactSection from './homepage/HomepageContactSection.js';
import "../../styles/about.css";
import "../../styles/card.css";
import "../../styles/about-responsive.css";



function About() {


  return (
    <div>
        <AboutTop  />
        <AboutSecondSection /> 
        <AboutTeamSection  />
        <HomepageContactSection  />
    </div>
  );
}

export default About;




