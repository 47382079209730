import Keynote from "../../../assets/speaker/keynote.png";
import speaker1 from "../../../assets/speaker/speaker1.png";
import speaker2 from "../../../assets/speaker/speaker2.png";
import speaker3 from "../../../assets/speaker/speaker3.png";
import speaker4 from "../../../assets/speaker/speaker4.png";
import speaker5 from "../../../assets/speaker/speaker5.png";
import speaker6 from "../../../assets/speaker/speaker6.png";
import speaker7 from "../../../assets/speaker/speaker7.png";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

function UnyccThird() {
  return (
    <div>
        <div className="unycc-thirdsection-container">
            <div className="unycc-thirdsection-content">
                {/* <div className="keynote-speaker-unycc-thirdsection">
                    <div className="unycc-third-section-title">
                        <div>
                            <h1>KEYNOTE SPEAKER</h1>
                            <hr className="title-divider-unycc-third" />
                        </div>  
                    </div>
                    <div className="speaker-photo-section keynote-speaker">
                        <div className="speaker-container" >
                            <img 
                                src="https://res.cloudinary.com/anujnirmal/image/upload/v1641997183/cards/session_speakers/session-02_hynoti.png" 
                                alt="keynote-speaker" 
                                className="speaker-image"
                            />
                        </div>
                    </div>
                </div> */}
                <div className="session-speakers-unycc-thirdsection">
                    <div className="unycc-third-section-title">
                        <div>
                            <h1>SESSION SPEAKERS</h1>
                            <hr className="title-divider-unycc-third" />
                        </div>
                    </div>
                    <div className="session-speakers">
                        <div className="session-row">
                            <div className="speaker-container" >
                                <div>
                                    <div className="speaker-container-text">
                                        <div>
                                            {/* <h5>SUPERPOWER 1</h5> */}
                                            <h4 className="orange">WOMEN IN POWER</h4>
                                        </div>
                                    </div>
                                    <div className="speakers-image-aligner">
                                        <img 
                                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1647612930/cards/session_speakers/Asset_26_roxkn0.png" 
                                            alt="keynote-speaker" 
                                            className="speaker-image"
                                        />
                                    </div>
                                    <div className="speaker-main-details">
                                      <div>
                                            <div className="speaker-main-session-name">
                                                <h3>Smriti Irani</h3>
                                            </div>
                                            <div className="speaker-main-session-designation">
                                                <h5>Union Minister for Women & Child Development</h5>
                                            </div>
                                      </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="speaker-container" >
                                <div>
                                    <div className="speaker-container-text">
                                        <div>
                                            {/* <h5>SUPERPOWER 1</h5> */}
                                            <h4 className="greenish">Policy</h4>
                                        </div>
                                    </div>
                                    <div className="speakers-image-aligner">
                                        <img 
                                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1648731426/cards/session_speakers/Poonam_lwnlf2.png" 
                                            alt="keynote-speaker" 
                                            className="speaker-image"
                                        />
                                    </div>
                                    <div className="speaker-main-details">
                                      <div>
                                            <div className="speaker-main-session-name">
                                                <h3>Poonam Mahajan</h3>
                                            </div>
                                            <div className="speaker-main-session-designation">
                                                <h5>Member of Parliament</h5>
                                            </div>
                                      </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="speaker-container" >
                                <div>
                                    <div className="speaker-container-text">
                                        <div>
                                            {/* <h5>SUPERPOWER 2</h5> */}
                                            <h4 className="pink">DIPLOMACY</h4>
                                        </div>
                                    </div>
                                    <div className="speakers-image-aligner">
                                        <img 
                                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1647612931/cards/session_speakers/Asset_20_wbe017.png" 
                                            alt="keynote-speaker" 
                                            className="speaker-image"
                                        />
                                    </div>
                                    <div className="speaker-main-details">
                                        <div>
                                            <div className="speaker-main-session-name">
                                                <h3>Shombi Sharp</h3>
                                            </div>
                                            <div className="speaker-main-session-designation">
                                                <h5>UN Resident Coordinator in India</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="speaker-container" >
                                <div>
                                    <div className="speaker-container-text">
                                        <div>
                                            {/* <h5>SUPERPOWER 3</h5> */}
                                            <h4 className="red">HEALTHCARE TECH</h4>
                                        </div>
                                    </div>
                                    <div className="speakers-image-aligner">
                                        <img 
                                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1647612931/cards/session_speakers/Asset_24_xfwppg.png" 
                                            alt="keynote-speaker" 
                                            className="speaker-image"
                                        />
                                    </div>
                                    <div className="speaker-main-details">
                                        <div>
                                            <div className="speaker-main-session-name">
                                                <h3>Adar Poonawalla</h3>
                                            </div>
                                            <div className="speaker-main-session-designation">
                                                <h5>CEO, Serum Institute</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            

                        </div>
                        <div className="session-row margin-negative-second-row">

                        <div className="speaker-container margin-second-row">
                                <div>
                                    <div className="speaker-container-text">
                                        <div>
                                            {/* <h5>SUPERPOWER 4</h5> */}
                                            <h4 className="red-pink">FILM</h4>
                                        </div>
                                    </div>
                                    <div className="speakers-image-aligner">
                                        <img 
                                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1648541362/cards/session_speakers/Kareena_tdvqre.png" 
                                            alt="keynote-speaker" 
                                            className="speaker-image"
                                        />
                                    </div>
                                    <div className="speaker-main-details">
                                        <div>
                                            <div className="speaker-main-session-name">
                                                <h3>Kareena Kapoor</h3>
                                            </div>
                                            <div className="speaker-main-session-designation">
                                                <h5>Film Actor</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        <div className="speaker-container margin-second-row" >
                                <div>
                                    <div className="speaker-container-text">
                                            <div>
                                                {/* <h5>SUPERPOWER 4</h5> */}
                                                <h4 className="red-pink">FILM</h4>
                                            </div>
                                        </div>
                                        <div className="speakers-image-aligner">
                                            <img 
                                                src="https://res.cloudinary.com/anujnirmal/image/upload/v1648541362/cards/session_speakers/rana_hpzgdq.png" 
                                                alt="keynote-speaker" 
                                                className="speaker-image"
                                            />
                                        </div>
                                        <div className="speaker-main-details">
                                            <div>
                                                <div className="speaker-main-session-name">
                                                    <h3>Rana Daggubati</h3>
                                                </div>
                                                <div className="speaker-main-session-designation">
                                                    <h5>Film Actor</h5>
                                                </div>
                                            </div>
                                        </div>
                                </div> 
                            </div>

                            <div className="speaker-container margin-second-row" >
                                <div>
                                    <div className="speaker-container-text">
                                            <div>
                                                {/* <h5>SUPERPOWER 5</h5> */}
                                                <h4 className="yellow">CONSCIOUS CAPITALISM</h4>
                                            </div>
                                        </div>
                                    <div className="speakers-image-aligner">
                                        <img 
                                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1647952825/cards/session_speakers/nikhil_kamat_qltr0u.png" 
                                            alt="keynote-speaker" 
                                            className="speaker-image"
                                        />
                                    </div>
                                    <div className="speaker-main-details">
                                        <div>
                                            <div className="speaker-main-session-name">
                                                <h3>Nikhil Kamath</h3>
                                            </div>
                                            <div className="speaker-main-session-designation">
                                                <h5>Co-Founder, Zerodha & True Beacon</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>

                            <div className="speaker-container margin-second-row" >
                                <div>
                                        <div className="speaker-container-text">
                                            <div>
                                                {/* <h5>SUPERPOWER 6</h5> */}
                                                <h4 className="blue">NUTRITION</h4>
                                            </div>
                                        </div>
                                        <div className="speakers-image-aligner">
                                            <img 
                                                src="https://res.cloudinary.com/anujnirmal/image/upload/v1647612930/cards/session_speakers/Asset_22_tdp6yf.png" 
                                                alt="keynote-speaker" 
                                                className="speaker-image"
                                            />
                                        </div>
                                        <div className="speaker-main-details">
                                            <div>
                                                <div className="speaker-main-session-name">
                                                    <h3>Bishow Parajuli</h3>
                                                </div>
                                                <div className="speaker-main-session-designation">
                                                    <h5>Representative and Country Director World Food Programme, India</h5>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                  
                            </div>

                           
        
                        </div>

<div className="session-row margin-negative-second-row">

<div className="speaker-container margin-third-row" >
    <div>
        <div className="speaker-container-text">
            <div>
            {/* <h5>SUPERPOWER 6</h5> */}
                <h4 className="yellow">CONSCIOUS CAPITALISM</h4>
            </div>
        </div>
        <div className="speakers-image-aligner">
            <img 
                src="https://res.cloudinary.com/anujnirmal/image/upload/v1647612930/cards/session_speakers/Asset_21_t8l0gy.png" 
                alt="keynote-speaker" 
                className="speaker-image"
            />
                                        </div>
                                        <div className="speaker-main-details">
                                            <div>
                                                <div className="speaker-main-session-name">
                                                    <h3>Roopa Kudva</h3>
                                                </div>
                                                <div className="speaker-main-session-designation">
                                                    <h5>Managing Partner, Omdiyar Network, India</h5>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                  
                            </div>

<div className="speaker-container margin-third-row" >
        <div>            
            <div className="speaker-container-text">
                    <div>
                        {/* <h5>SUPERPOWER 4</h5> */}
                        <h4 className="red">HEALTHCARE TECH</h4>
                    </div>
                </div>
                <div className="speakers-image-aligner">
                    <img 
                        src="https://res.cloudinary.com/anujnirmal/image/upload/v1647952825/cards/session_speakers/AMIT_frxxab.png" 
                        alt="keynote-speaker" 
                        className="speaker-image"
                    />
                </div>
                <div className="speaker-main-details">
                    <div>
                        <div className="speaker-main-session-name">
                            <h3>Amit Mookim</h3>
                        </div>
                        <div className="speaker-main-session-designation">
                            <h5>Managing Director, IQVIA South Asia</h5>
                        </div>
                    </div>
                </div>
        </div> 
    </div>

    <div className="speaker-container margin-third-row" >
        <div>
            <div className="speaker-container-text">
                    <div>
                        {/* <h5>SUPERPOWER 5</h5> */}
                        <h4 className="light-blue">STORYTELLING</h4>
                    </div>
                </div>
            <div className="speakers-image-aligner">
                <img 
                    src="https://res.cloudinary.com/anujnirmal/image/upload/v1647668790/cards/session_speakers/Asset_30_uq5c4q.png" 
                    alt="keynote-speaker" 
                    className="speaker-image"
                />
            </div>
            <div className="speaker-main-details">
                <div>
                    <div className="speaker-main-session-name">
                        <h3>Diksha Radhakrishan</h3>
                    </div>
                    <div className="speaker-main-session-designation">
                        <h5>Deputy Director (Behavioural Design & Impact) at Centre for Social and Behaviour Change</h5>
                    </div>
                </div>
            </div>
        </div> 
    </div>

                    </div>
                        <div className="unycc-city-center unycc-city-button-center">
                            <Link className="link-decoration" to="/register"><Button variant="outlined" className="homepage-second-section-button red-to-blue">APPLY TO ATTEND</Button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default UnyccThird;
