import React, {useRef} from "react";
import CountUp from 'react-countup';

function FloatingData() {
 

  return (
    <div>
     
    
      <div className="floating-info-container hidden-on-desktop">
          <div className="floating-info">
            <h4 className="info"><span className="info-highlight">
            <CountUp 
              start={0} 
              duration={2.75} 
              end={80} 
              delay={0.5}
            />  
            </span><br/>CITIES</h4>
            <h4 className="info"><span className="info-highlight">
            <CountUp 
              start={0} 
              duration={2.75} 
              end={14} 
              delay={0.5}
            />   
            </span><br/>CONCLAVES</h4>
            <h4 className="info"><span className="info-highlight">
            <CountUp 
              start={0} 
              duration={2.75} 
              end={100} 
              delay={0.5}
            /> 
            </span><br/>SPEAKERS</h4>         
            <h4 className="info"><span className="info-highlight"> 
            <CountUp 
              start={0} 
              duration={2} 
              end={100000} />
                +</span><br/>PARTICIPANTS</h4>
          </div>
      </div>

      <div className="floating-info-container-mobile hidden-on-mobile">
        <div className="floating-info-flexbox">
          <div className="floating-info">
            <div className="floating-info-row">
              <h4 className="info"><span className="info-highlight">
                  <CountUp 
                    start={0} 
                    duration={2.75} 
                    end={80} 
                    delay={0.5}
                  />    
              </span><br/>CITIES</h4>
              <h4 className="info"><span className="info-highlight">
                <CountUp 
                  start={0} 
                  duration={2.75} 
                  end={14} 
                  delay={0.5}
                />     
              </span><br/>CONCLAVES</h4>
            </div>
            <div className="floating-info-row">
              <h4 className="info"><span className="info-highlight">
                <CountUp 
                  start={0} 
                  duration={2.75} 
                  end={100} 
                  delay={0.5}
                />   
              </span><br/>SPEAKERS</h4>         
              <h4 className="info"><span className="info-highlight">
                <CountUp 
                  start={0} 
                  duration={2} 
                  end={100000} 
                  delay={0.5}
                />  
               +</span><br/>PARTICIPANTS</h4>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  );
}

export default FloatingData;
