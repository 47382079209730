import ImgCity from "../../../assets/event-info/city.png";
import ImgDate from "../../../assets/event-info/date.png";
import ImgLocation from "../../../assets/event-info/loc.png";

function EventInfo() {
  return (
    <div>
        <div className="venue-details">
            <img 
              src={ImgCity} 
              alt="info-venue"
              className="location location-city" 
            />
            <img 
              src={ImgDate} 
              alt="info-venue" 
              className="location location-venue"
            />
            <img 
              src={ImgLocation} 
              alt="info-venue" 
              className="location location-venue"
            />
            {/* <div className="location location-city"></div>
            <div className="location location-venue"></div>
            <div className="location location-date"></div> */}
        </div>
    </div>
  );
}

export default EventInfo;
