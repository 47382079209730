import React, {useEffect} from "react";
import Team from "../about/Team.js";
import Teammembers from "./teammembers.js";
import Advisory from "./advisory-board.js";


function AboutTeamSection() {
    
    
  return (
    <div>
        <div className="about-team-flex-container">
            <div  className="about-team">
                <div className="about-team-titile" >
                    <div>
                        <h1>Meet the Team</h1>
                        <hr className="about-team-divider"/>
                    </div>
                </div>
                <div className="team-container-about-team">
                    <div className="team-container">
                        <div className="align-photo-center">
                            <div className="team-first-row">
                                {Teammembers.slice(0, 4).map((person) => (
                                    <Team 
                                        img={person.img} 
                                        key={person.id} 
                                        name={person.name} 
                                        designation={person.designation}
                                    />
                                ))}
                            </div>
                            <div className="team-second-row">
                                {Teammembers.slice(4, 8).map((person) => (
                                    <Team 
                                        img={person.img} 
                                        key={person.id} 
                                        name={person.name} 
                                        designation={person.designation}
                                    />
                                ))}
                            </div>
                            <div className="team-third-row">
                                {Teammembers.slice(8, 12).map((person) => (
                                    <Team 
                                        img={person.img} 
                                        key={person.id} 
                                        name={person.name} 
                                        designation={person.designation}
                                    />
                                ))}
                            </div>
                            <div className="team-fourth-row">
                                {Teammembers.slice(12, 13).map((person) => (
                                    <Team 
                                        img={person.img} 
                                        key={person.id} 
                                        name={person.name} 
                                        designation={person.designation}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-team-titile advisory-board" >
                    <div>
                        <h1>Advisory Board</h1>
                        <hr className="about-team-divider"/>
                    </div>
                </div>
                <div className="team-container-about-team">
                    <div className="team-container">
                        <div>
                            <div className="team-first-row">
                                {Advisory.slice(0, 2).map((person) => (
                                    <Team 
                                        img={person.img} 
                                        key={person.id} 
                                        name={person.name} 
                                        designation={person.designation}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default AboutTeamSection;
