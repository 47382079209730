import React from 'react'
import PastSpeakersTop from './past-speakers/PastSpeakersTop';
import PastSpeakersSecondSection from './past-speakers/PastSpeakersSecondSection';
import HomepageContactSection from "./homepage/HomepageContactSection.js";
import PastSpeakerImg1 from "../../assets/past-speakers/past-speaker1.png";
import "../../styles/pastspeakers.css"
import "../../styles/pastspeakers-responsive.css";


function PastSpeaker() {
    return (
        <div>
            <PastSpeakersTop />
            <PastSpeakersSecondSection />
            <HomepageContactSection />
        </div>
    )
}

export default PastSpeaker
