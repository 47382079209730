import React from 'react';
import MediaTop from "./media/MediaTop.js";
import MediaSecondSection from './media/MediaSecondSection.js';
import HomepageContactSection from "./homepage/HomepageContactSection.js";
import "../../styles/media.css";
import "../../styles/media-responsive.css";

function Media() {
    return (
        <div>
            <MediaTop />
            <MediaSecondSection />
            <HomepageContactSection />
        </div>
    )
}

export default Media
