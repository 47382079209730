import React from 'react';
import {Link} from "react-router-dom";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SuperPowerLogo from "../../../assets/7_SUPER_POWER-01.png";
import "../../../styles/HomepageBanner.css";
import Image1 from "../../../assets/bannerimage/shombi.png";
import Image2 from "../../../assets/bannerimage/poonam.png";
import Image3 from "../../../assets/bannerimage/smriti.png";
import Image4 from "../../../assets/bannerimage/kareena.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const HompageBanner = () => {
  return (
    <div>
        <div className='banner-top-container'>
          <div className="heading-top heading-top-mobile-homepage hompage-ban-top">
              <div className="heading-top-text-container">
                <h1 className="heading-text">UNYCC 2022</h1>
                <h1 className="heading-back">UNYCC 2022</h1> 
              </div>
          </div>
          
          
          <div className='second-row-banner'>
           
      
                <div className='superpower-logo-top-banner'>
                  <img 
                    src={SuperPowerLogo} 
                    alt="superpowerlogo"
                    className='superpower-logo-banner'
                  />
                </div>

                <div className='event-info-banner-top'>
                  <h2 className='event-info-banner-text'>APRIL 9th | MUMBAI</h2>   
                </div>

                <div className='confirmed-speakers-box'>
                <div className='flex-box-confirmed-speaker'>
                  {/* <div className='first-row-banner'> */}
                    <div className='conf-img-holder'>
                      <img 
                        src={Image3}
                        alt='speaker photos'
                        className='conf-speaker'
                      />
                    </div>
                    <div className='conf-img-holder'>
                      <img 
                        src={Image2}
                        alt='speaker photos'
                        className='conf-speaker'
                      />
                    </div>
                  {/* </div> */}

                  {/* <div className='second-row-banner'> */}
                    <div className='conf-img-holder'>
                      <img 
                        src={Image1}
                        alt='speaker photos'
                        className='conf-speaker'
                      />
                    </div>
                    <div className='conf-img-holder'>
                      <img 
                        src={Image4}
                        alt='speaker photos'
                        className='conf-speaker'
                      />
                    </div>
                  {/* </div> */}
                </div>
            
              </div>
            
            <div className="event-button-container but-pos-homepage">
              <Link className="link-decoration" to="/cityUnycc">
                <Button variant="outlined" className="homepage-second-section-button red-to-blue">
                  Know More
                </Button>
              </Link>
          </div>
          </div>

        </div>
    </div>
  )
}

export default HompageBanner