import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import mediaList from './mediaList';
// Import Media assets
import Media1 from "../../../assets/media/media1.png";
import Media2 from "../../../assets/media/media2.png";
import Media3 from "../../../assets/media/media3.png";
import Media4 from "../../../assets/media/media4.png";
import Media5 from "../../../assets/media/media5.png";
import Media6 from "../../../assets/media/media6.png";


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function MediaSecondSection() {
    return (
        <div>
            <div className='media-second-section-container'>
            <Box 
                className='media-article-main-container' 
                sx={{ flexGrow: 1 }}
            >
                <Grid 
                    container 
                    spacing={0} 
                    className="media-article-second-section-flex"        
                >

                    {mediaList.map(media => (
                        <Grid 
                            item 
                            md={4}
                            lg={4}
                            sm={6}
                            xs={12}
                            className={media.class}
                        >
                        <a href={media.link} target="_blank">
                            <img 
                                src={media.img}
                                alt="media articles" 
                                className="media-article-img"
                            />
                        </a>
                        </Grid>
                    ))}
                    
                </Grid>
            </Box>
            </div>
        </div>
    )
}

export default MediaSecondSection

