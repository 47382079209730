import React from 'react';
import "../styles/footer.css";
import { FaYoutube, FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';


const Footer = () => {
    return (
      <footer className='footer'>
            <div className='social-media-container'>
                <a 
                    className='links-social-media'
                    href="https://www.youtube.com/channel/UCUVA-sfWjvCO58bjityrk4A"target="_blank" >
                        <FaYoutube className='icon-social-media' />
                </a>
                <a 
                    className='links-social-media'
                    href="https://www.facebook.com/youngchangemakersconclave/" target="_blank" >
                        <FaFacebookF className='icon-social-media' />
                </a>
                <a 
                    className='links-social-media'
                    href="https://twitter.com/unic_ycc" target="_blank" >
                        <FaTwitter className='icon-social-media' />
                </a>
                <a 
                    className='links-social-media'
                    href="https://www.linkedin.com/company/13226014" target="_blank" >
                        <FaLinkedinIn className='icon-social-media' />
                </a>
                <a 
                    className='links-social-media'
                    href="https://www.instagram.com/youngchangemakersconclave/" target="_blank" >
                        <FaInstagram className='icon-social-media' />
                </a>
            </div>
            <div className='copyright-container'>
                <p>&copy; 2022 Youngchangemakersconclave. All rights reserved.</p>
            </div>
        </footer>
    )      
}

export default Footer
