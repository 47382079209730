import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';



  

function PastSpeakersTop() {
    return (
        <div>
          <div className='pastspeaker-top-container'>
            <div className="heading-top past-speaker-heading-top">
                <div className="heading-top-pos">
                    <div className="heading-top-text-container">
                        <h1 className="heading-text">PAST SPEAKERS</h1>
                        <h1 className="heading-back">PAST SPEAKERS</h1> 
                    </div>
                </div>
            </div>
          </div>
        </div>
    )
}

export default PastSpeakersTop
