import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import HomepageContactForm from "./HomepageContactForm.js";


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

function HomepageContactSection(){
  

  return (
    <div>
        <div id="contact-page" className='homepage-contact-container'>
            <Box sx={{ flexGrow: 1 }}>
            <Grid container className="homepage-contact-flex">
                <Grid item  lg={6} sm={12} xs={12}>
                    <HomepageContactForm />
                </Grid>
                <Grid item lg={6} sm={12} xs={12} className='center-container-contact-section-details'>
                    <div className='homepage-contact-about-container'>
                        <div className='homepage-contact-about'>
                            <h2 className='homepage-contact-title'>Contact Us</h2>
                            <hr className='hr-homepage-contact'/>
                            <p><span className='email-contacts-heading'>Email:</span> copresidents@youngchangemakersconclaveindia.in</p>
                            <p className='contact-us-bottom-spl'><span className='email-contacts-heading'>Contact No:</span> +91 7738013511</p>
                           {/* <p className='homepage-contact-text'>
                                The Young Changemakers Conclave (YCC) was founded in 2011 by
                                X-Billion Skills Lab and United Nations India Centre. YCC's core
                                purpose was to create a platform for students and young entre
                                preneurs to engage with leaders from the domains of politics,
                                business, media, the arts, technology, sports and entertainment.
                                The Goal then was to seed perspectives, ideas and missions in the
                                minds of our nation’s future. With time, the platform’s purpose
                                and target has evolved.
                            </p>
                            <p className='homepage-contact-text'>
                                Our aim now is to raise awareness amongst citizens about how
                                factors such as emerging technology, epidemics, artificial intelli
                                gence, climate change, overpopulation and automation will affect
                                their quality of life. More specifically, we will adapt and contextu
                                alize our content to connect with rural & semi urban populations
                                which constitute the majority of our nation’s demographic.
                            </p> */}
                        </div>
                    </div>
                </Grid>
            </Grid>
            </Box>
        </div>
    </div>
  );
}

export default HomepageContactSection;
