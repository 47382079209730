

function Cards() {
  return (
    <div className="cards">
        <div className="card card1"></div>
        <div className="card card2"></div>
        <div className="card card3"></div>
        <div className="card card4"></div>
        <div className="card card5"></div> 
    </div>
  );
}
export default Cards;
