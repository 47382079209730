import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import EventInfo from './EventInfo.js';
import SuperPowerLogo7 from "../../../assets/7_SUPER_POWER-01.png";


function App() {

  
  return (
    <div>
        <div className="hompage-second-section">
              <div  className="homepage-secon-innerflex">
                <div className="homepage-secon-innerflex">
                    <div className="superpowerlogo-container">
                        <img  className="superpower-logo" src={SuperPowerLogo7} alt="7 Super Power Logo" />
                    </div>
                </div>
              </div>
              <div  className="homepage-secon-innerflex">
                  <div className="venue-info-container">
                    <div className="venue-info-smaller-container">
                        <h2 className="venue-page-title">U.N. Young Changemakers Conclave 2022</h2>
                        <hr className="title-divider"/>
                        <nav className="location-responsive">
                          <ul className="location-menu">
                            <li className="active">MUMBAI</li>
                            <li>SRINAGAR</li>
                            <li>NEW DELHI</li>
                            <li>SHILLONG</li>
                            <li>GANDHINAGAR</li>
                            <li>HYDERABAD</li>
                          </ul>
                        </nav>
                        <div className="venue-description">
                          <p className="venue-page-description">
                            Factors like climate change, overpopulation, professional redundancy caused by automation and rising inequality will cause significant negative impact on humanity. It is therefore imperative to have a dialogue on how emerging  technologies, progressive policy and behavior change can be leveraged to secure a quality life for future generations. 
                          </p>
                        </div>
                        <div className="event-container">
                          <EventInfo />
                        </div>
                        <div className="event-button-container">
                          <Link className="link-decoration" to="/cityUnycc#contact-page"><Button variant="outlined" className="homepage-second-section-button red-to-blue">KNOW MORE</Button></Link>
                        </div>
                      </div>
                  </div> 
            </div>
               
          </div>
    </div>
  );
}

export default App;
