import React, {useEffect} from "react";

function AboutTop() {
  
  return (
    <div>
      <div  className="aboutpage-top-container">
          <div className="heading-top about-heading-top-section">
            <div className="heading-top-pos">
              <div className="heading-top-text-container">
                  <h1 className="heading-text">ABOUT US</h1>
                  <h1 className="heading-back">ABOUT US</h1> 
              </div>
            </div>
            </div>
      </div>
    </div>
  );
}

export default AboutTop;
