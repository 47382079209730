import SLetter from "../../../assets/letters/s.png";
import MLetter from "../../../assets/letters/m.png";
import ALetter from "../../../assets/letters/a.png";
import RLetter from "../../../assets/letters/r.png";
import TLetter from "../../../assets/letters/t.png";


function HomepageMissionSection(){
  return (
  <div>
      <div className="homepage-mission-container">
          <div   className="homepage-mission-flex">
            <div className="homepage-vision-text">
                <h2>VISION</h2>
                <p>
                    To empower young Indians living in cities, semi urban areas and villages with information about how the world is changing, what it means for them and the role they can play in protecting humanity. 
                </p>
            </div>
            <div className="homepage-mission-text">
                <h2>MISSION</h2>
                <p>
                    To enable citizens to become
                </p>
            </div>
            {/* 
            <div className="homepage-mission-smart-container mobile-hidden">
                <h1>
                    <span className="s-mobile">S<span className="dot">.</span></span><br />
                    <span className="m-mobile">M<span className="dot">.</span></span><br />
                    <span className="a-mobile">A<span className="dot">.</span></span><br />
                    <span className="r-mobile">R<span className="dot">.</span></span><br />
                    <span className="t-mobile">T</span>
                </h1>
                <ul>
                    <li>SUSTAINABLE</li>
                    <li>MINDFUL</li>
                    <li>AWARE</li>
                    <li>RESILIENT</li>
                    <li>TOLERANT</li>
                </ul>
            </div> */}

            <div className="homepage-mission-smart-container hide-on-phone">
                <h1>S<span className="dot">.</span>M<span className="dot">.</span>A<span className="dot">.</span>R<span className="dot">.</span>T</h1>
                <ul className="mobile-menu-flex">
                    <li>SUSTAINABLE</li>
                    <li>MINDFUL</li>
                    <li>AWARE</li>
                    <li>RESILIENT</li>
                    <li>TOLERANT</li>
                </ul>
            </div>
            
            <div className="hide-on-desktop" >
                <div className="letters s-container">
                <div className="letter-inner-container">
                <div className="inner-flex-container-letter"></div>
                    <img 
                            className="letter-mobile"
                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1641982251/cards/SMART/Asset_1_rbtcks.png" 
                            alt="smart container"
                    />
                </div>
                <div className="text-smart-container">
                    <h4>USTAINABLE</h4>
                </div>
                </div>
                <div className="letters m-container">
                <div className="letter-inner-container">
                <div className="inner-flex-container-letter"></div>
                    <img 
                            className="letter-mobile"
                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1641982251/cards/SMART/Asset_2_eru2a4.png" 
                            alt="smart container"
                    />
                </div>
                <div className="text-smart-container">
                    <h4>INDFUL</h4>
                </div>  
                </div>
                <div className="letters a-container">
                    <div className="letter-inner-container">
                    <div className="inner-flex-container-letter"></div>
                        <img 
                            className="letter-mobile"
                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1641982251/cards/SMART/Asset_3_pqqhb0.png" 
                            alt="smart container"     
                        />
                    </div>
                    <div className="text-smart-container">
                        <h4>WARE</h4>
                    </div> 
                </div>
                <div className="letters r-container">
                <div className="letter-inner-container">
                    <div className="inner-flex-container-letter"></div>
                    <img 
                            className="letter-mobile"
                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1641982251/cards/SMART/Asset_4_wcllvq.png" 
                            alt="smart container"
                        />
                </div>
                <div className="text-smart-container">
                    <h4>ESILIENT</h4>
                </div>    
                </div>
                <div className="letters t-container">
                <div className="letter-inner-container">
                    <img 
                            className="letter-mobile"
                            src="https://res.cloudinary.com/anujnirmal/image/upload/v1641982251/cards/SMART/Asset_5_hzpn0w.png" 
                            alt="smart container"
                    />
                </div>
                <div className="text-smart-container">
                    <h4>OLERANT</h4>           
                </div>
 
                </div>
            </div>
        </div>
      </div>
  </div>
  );
}

export default HomepageMissionSection;
