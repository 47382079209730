import React, {useState, useEffect} from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Flickity from 'react-flickity-component'
import HomeSpeaker1 from "../../../assets/speaker-home/Speaker-01.png";
import HomeSpeaker2 from "../../../assets/speaker-home/Speaker-02.png";
import HomeSpeaker3 from "../../../assets/speaker-home/Speaker-03.png";
import HomeSpeaker4 from "../../../assets/speaker-home/Speaker-04.png";
// import HomeSpeaker5 from "../../../assets/speaker-home/Speaker-05.png";
import '@splidejs/splide/dist/css/splide.min.css';
import "../../../styles/carousel.css";

// const images = [
//   { url: "https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1640776585/cards/Speaker-01_x94jmu.png" },
//   { url: "https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1640776589/cards/Speaker-02_u4kchv.png" },
//   { url: "https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1640776590/cards/Speaker-03_wtwgts.png" },
//   { url: "https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1640776593/cards/Speaker-04_i6lah4.png" },
//   { url: "https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1640776585/cards/Speaker-01_x94jmu.png" },
// ];




function HomepageScroll() {

  const [isBigDevice, setIsBigDevice] = useState(1);
  const [gapSlide, setGapSlide] = useState('0rem');
  const [pages, setPages] = useState(5)
  const [height, setHeight] = useState("16rem");

  useEffect(() => {
    let windowWidht = window.innerWidth;
    setSlider(windowWidht);
  }, []);

  function setSlider(width){
   
    if(width <= 1336) {
      setIsBigDevice(0)
      setPages(4)
    }

    if(width <= 1010) {
      setPages(3)
    }
   
    if(width <= 770) {
      setPages(2)
    }

    if(width <= 520) {
      setPages(1)
      setHeight("20rem")
    }

    if(width <= 350) {
      setPages(1)
      setHeight("18rem")
    }

    if(width <= 1400) {
      setGapSlide('0.7rem')
    }
    
  }


  return (
    <div className='homepage-scroll-container'>
      <div>
      <Splide
       options={{
        autoplay: true,
        perPage: pages,
        height: height,
        rewind: true,
        gap: gapSlide,
        drag: true,
        pauseOnHover: false,
        resetProgress: false,
        interval: 1000,
      }}
      onMoved={(splide, newIndex) => {
        // eslint-disable-next-line
        // console.log("moved", newIndex);

        // // eslint-disable-next-line
        // console.log("length", splide.length);
      }}
      className="scroll-container"
      >
        <SplideSlide>
          <img  className="image-slider-splide" src="https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1641909196/cards/Speaker-04_jml8db.png" alt="Image 1"/>
        </SplideSlide>
        <SplideSlide>
          <img className="image-slider-splide" src="https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1641909196/cards/Speaker-05_eymxcr.png" alt="Image 2"/>
        </SplideSlide>
        <SplideSlide>
          <img  className="image-slider-splide" src="https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1641909196/cards/Speaker-02_kkzehf.png" alt="Image 2"/>
        </SplideSlide>
        <SplideSlide>
          <img className="image-slider-splide"  src="https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1641909196/cards/Speaker-03_vhpiji.png" alt="Image 2"/>
        </SplideSlide>
        <SplideSlide>
          <img className="image-slider-splide"  src="https://res.cloudinary.com/anujnirmal/image/upload/c_scale,h_250/v1641909196/cards/Speaker-01_hqm34u.png" alt="Image 2"/>
        </SplideSlide>
      </Splide>

      </div>
      
    </div>
  );
}

export default HomepageScroll;



