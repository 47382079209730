import React, {useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import Headerlogo from "../assets/logos/head-logo.png";
import "../styles/navigation.css";
import "../styles/header.css";


  

function Navbar() {

    const location = useLocation();
   

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    const [isHome, setIsHome] = useState(true);
    const [isAbout, setIsAbout] = useState(false);
    const [isMedia, setIsMedia] = useState(false);
    const [isContact, setIsContact] = useState(false);
    const [isPast, setIsPast] = useState(false);
    const [isUnycc, setIsUnycc] = useState(false);
    const [isRegister, setIsRegister] = useState(false);

    function openNav() {
        setIsNavbarOpen(true);
    }

    function closeNav(e) {
        // e.preventDefault();
        setIsNavbarOpen(false);
    }

    useEffect(() => {  
        const url = location.pathname;
        getPosition(url)
       
    }, []);

    function getPosition(urlPath) {
           
        
     
        if(urlPath == "/home"){
            setIsHome(true);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(urlPath == "/about"){
            setIsHome(false);
            setIsAbout(true);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(urlPath == "/media"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(true);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(urlPath == "/contact") {
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(true);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(urlPath == "/pastspeakers"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(true);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(urlPath == "/unycc"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(true);
            setIsRegister(false);
        }
        if(urlPath == "/register"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(true);
        }
        if(urlPath == "/cityUnycc"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(true);
            setIsRegister(false);
        }
    }

    function handleNavClick(e){
        const navId = e.target.id;
        if(navId == "home"){
            setIsHome(true);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(navId == "about"){
            setIsHome(false);
            setIsAbout(true);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(navId == "media"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(true);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(navId == "contact") {
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(true);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(navId == "past"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(true);
            setIsUnycc(false);
            setIsRegister(false);
        }

        if(navId == "unycc"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(true);
            setIsRegister(false);
        }

        if(navId == "register"){
            setIsHome(false);
            setIsAbout(false);
            setIsMedia(false);
            setIsContact(false);
            setIsPast(false);
            setIsUnycc(false);
            setIsRegister(true);
        }


    }

  return (
    <div>
        <header className="header-main">
            <div className="logo-container-header">
                <Link to="/" >
                <img 
                    src={Headerlogo} 
                    alt="main-logo" 
                    className="unycc-logo-main"
                />
                </Link>
            </div>
            <nav className="navbar">
                <ul className="navigation-main">
                    <li ><Link 
                            className={ isHome ? "nav-links active-menu" : "nav-links"} 
                            to="/" 
                            id="home"
                            onClick={handleNavClick}
                            >HOME</Link></li>
                    <li><Link 
                            className={ isAbout ? "nav-links active-menu" : "nav-links"} 
                            to="/about"
                            id="about" 
                            onClick={handleNavClick}
                            >ABOUT US</Link></li>
                    <li><Link 
                            className={ isMedia ? "nav-links active-menu" : "nav-links"} 
                            to="/media" 
                            id="media"
                            onClick={handleNavClick}
                            >MEDIA</Link></li>
                    <li><a 
                            className={ isContact ? "nav-links active-menu" : "nav-links"} 
                            href="#contact-page" 
                            id="contact"
                            onClick={handleNavClick}
                            >CONTACT</a></li>
                    <li><Link 
                            className={ isPast ? "nav-links active-menu" : "nav-links"} 
                            to="/pastspeakers" 
                            id="past"
                            onClick={handleNavClick}
                            >PAST SPEAKERS</Link></li>
                    <li><Link 
                            className={ isUnycc ? "nav-links active-menu" : "nav-links"} 
                            to="/unycc"
                            id="unycc" 
                            onClick={handleNavClick}
                            >UNYCC 2022</Link></li>
                    <li><Link 
                            className={ isRegister ? "nav-links active-menu" : "nav-links"} 
                            to="/register"
                            id="register" 
                            onClick={handleNavClick}
                            >APPLY</Link></li>
                </ul>
                <div className="navbar-hamburger-container hid-nav-on-pc">
                <div className="hamburger-menu" onClick={openNav}>
                    <div className="hamburger"></div>
                    <div className="hamburger"></div>
                    <div className="hamburger"></div>
                </div>
                </div>
                
            </nav>
        </header>
        <div id="myNav" className="overlay" style={{
            height: isNavbarOpen ? '100%' : "0%",
        }}>

            <a 
                href="#" 
                className="closebtn" 
                onClick={closeNav}    
            >&times;</a>
 
            <div className="overlay-content">
                <div>
                    <Link className="links" to="/"  onClick={closeNav} >HOME</Link><hr className="nav-divider-mobile" />
                    <Link className="links" to="/about"  onClick={closeNav} >ABOUT US </Link><hr className="nav-divider-mobile" />
                    <Link className="links" to="/media"  onClick={closeNav} >MEDIA </Link><hr className="nav-divider-mobile" />
                    <a className="links" href="#contact-page"  onClick={closeNav} >CONTACT</a><hr className="nav-divider-mobile" />
                    <Link className="links" to="/pastspeakers"  onClick={closeNav} >PAST SPEAKERS </Link><hr className="nav-divider-mobile" />
                    <Link className="links" to="/unycc"  onClick={closeNav} >UNYCC 2022 </Link><hr className="nav-divider-mobile" />
                    <Link className="links" to="/register"  onClick={closeNav} >APPLY</Link>
                </div>
            </div>

        </div>

    
    </div>
  );
}

export default Navbar;


