import React from 'react'
import "../../styles/cities.css"
import Button from '@mui/material/Button';
import Tooltip,  { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Grow } from '@mui/material';   
import {Link} from "react-router-dom";
import { motion } from "framer-motion";
// importing cities
import City1 from "../../assets/cities/City_MUMBI.png";
import City2 from "../../assets/cities/City_SHILLONG.png";
import City3 from "../../assets/cities/City_SRINAGAR.png";
import City4 from "../../assets/cities/City_GANDHINAGAR.png";
import City5 from "../../assets/cities/City_NEWDELHI.png";
import City6 from "../../assets/cities/City_Hyderabad.png";



const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

const pageVarients = {
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      sclae: 0,
    }
  }
  

  

function Cities() {

    

    return (
        <div>
            <div className='cities-main-container'>
                <div className="heading-top headingtop-cities">
                    <div className="heading-top-pos ">
                        <div className="heading-top-text-container heading-top-cities-h1">
                            <h1 className="heading-text">UN Young changemakers Conclave 2022</h1>
                            <h1 className="heading-back">UN Young changemakers Conclave 2022</h1> 
                        </div>
                    </div>
                </div>
                <div className='cities-absolute-pos'>
                <div className='select-a-city'>
                    
                    <div className='select-a-city-text'>
                        <p>Please select city</p>
                    </div>
                    <div className='select-a-city-cities'>
                    <motion.div
                        initial="out"  
                        animate="in"
                        exit="out"
                        variants={pageVarients}
                        transition={{duration: 0.5, delay:0.4}}
                    >
                        <Link to="/cityUnycc"><img 
                            src={City1} 
                            alt='cities'
                            className="citi-img-png allowed mumbai-select"    
                        />
                        </Link>
                    </motion.div>
                    <motion.div
                        initial="out"  
                        animate="in"
                        exit="out"
                        variants={pageVarients}
                        transition={{duration: 0.5, delay:0.5}}
                    >
                        <LightTooltip title="Coming Soon" placement="top" >
                            <img 
                                src={City2} 
                                alt='cities'
                                className="citi-img-png not-allowed"    
                            />
                        </LightTooltip>
                    </motion.div>
                    <motion.div
                        initial="out"  
                        animate="in"
                        exit="out"
                        variants={pageVarients}
                        transition={{duration: 0.5, delay:0.6}}
                    >
                         <LightTooltip title="Coming Soon" placement="top" >
                            <img 
                                src={City3} 
                                alt='cities'
                                className="citi-img-png not-allowed"    
                            />
                         </LightTooltip>
                    </motion.div>
                    <motion.div
                        initial="out"  
                        animate="in"
                        exit="out"
                        variants={pageVarients}
                        transition={{duration: 0.5, delay:0.7}}
                    >
                         <LightTooltip title="Coming Soon" placement="top" >
                            <img 
                                src={City4} 
                                alt='cities'
                                className="citi-img-png not-allowed"    
                            />
                         </LightTooltip>
                    </motion.div>
                    <motion.div
                        initial="out"  
                        animate="in"
                        exit="out"
                        variants={pageVarients}
                        transition={{duration: 0.5, delay:0.8}}
                    >
                        <LightTooltip title="Coming Soon" placement="top" >
                            <img 
                                src={City5} 
                                alt='cities'
                                className="citi-img-png not-allowed"    
                            />
                        </LightTooltip>
                    </motion.div>
                    <motion.div
                        initial="out"  
                        animate="in"
                        exit="out"
                        variants={pageVarients}
                        transition={{duration: 0.5, delay:0.9}}
                    >
                        <LightTooltip title="Coming Soon" placement="top" >
                            <img 
                                src={City6} 
                                alt='cities'
                                className="citi-img-png not-allowed"    
                            />
                        </LightTooltip>  
                    </motion.div>
                      
                    </div>
                    <div className="btn-cities-section-enter">
                    <Link className='link-style' to="/cityUnycc"><Button variant="outlined" className="homepage-second-section-button red-to-blue">ENTER</Button></Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Cities
