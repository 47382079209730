import React, {useEffect} from "react";
import Header from "./Header.js";
import '../styles/homepage.css';
import {BrowserRouter as Router} from "react-router-dom";


function App() {
 

  return (
      <Router>
        <div><Header /></div>
      </Router>
  );
}

export default App;
