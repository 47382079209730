import React from 'react'

const RegistrationTop = () => {
    return (
        <div>
            <div  className="aboutpage-top-container registration-background-image">
                <div className="heading-top about-heading-top-section">
                    <div className="heading-top-pos">
                        <div className="heading-top-text-container">
                            <h1 className="heading-text">APPLY TO ATTEND UNYCC 2022</h1>
                            <h1 className="heading-back">APPLY TO ATTEND UNYCC 2022</h1> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationTop
