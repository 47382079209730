import Team1 from "../../../assets/team/team1.png";

const mediaList = [
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-11_dzqduu.png",
      link: "https://www.firstpost.com/business/idea-behind-un-young-changemakers-conclave-is-to-trigger-an-idea-support-social-entrepreneurs-says-president-samyak-chakrabarty-5402161.html",
      id: 1 ,    
      class: 'starting-med media-article-single-container' 
    },  
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052608/cards/news_articles/NEWS_ARTICAL-15_f92goc.png",
      link: "http://everythingexperiential.businessworld.in/article/UN-Young-Changemakers-Conclave-to-be-held-on-October-27-in-Mumbai/21-09-2018-160594/",
      id: 2,
      class:'media-article-single-container'  
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-08_icr57g.png",
      link: "https://www.entrepreneur.com/article/309577",
      id: 3,
      class:'end-med media-article-single-container'  
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-06_cmh3lu.png",
      link: "https://www.asianage.com/india/all-india/250218/diversity-key-to-resilience-success.html",
      id: 4,
      class:'starting-med media-article-single-container'  
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052608/cards/news_articles/NEWS_ARTICAL-14_msjnfv.png",
      link: "https://www.firstpost.com/business/when-goqii-impacts-a-billion-people-i-will-consider-myself-successful-says-ceo-founder-vishal-gondal-5402891.html",
      id: 5,
      class:'media-article-single-container'  
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-04_fheckt.png",
      link: "https://www.ndtv.com/india-news/indian-youth-have-potential-to-change-world-us-consul-general-edgard-kagan-1938679",
      id: 6,
      class:'end-med media-article-single-container'  
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052608/cards/news_articles/NEWS_ARTICAL-16_qscqvt.png",
      link: "",
      id: 7,
      class:'starting-med media-article-single-container'  
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-13_l2vnvg.png",
      link: "https://indianexpress.com/article/entertainment/bollywood/varun-dhawan-to-address-un-conclave-today/",
      id: 8,
      class:'media-article-single-container'  
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-05_ty0v28.png",
      link: "https://www.asianage.com/sports/in-other-sports/180320/ioc-member-calls-tokyo-olympics-plans-insensitive-irresponsible.html",
      id: 9,
      class:'end-med media-article-single-container'  
    },  
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-10_zqycie.png",
      link: "https://www.moneycontrol.com/news/business/economy/guv-rajan-atun-young-changemakers-conclave-1203769.html",
      id: 10,
      class: 'starting-med media-article-single-container' 
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-09_h7nr5q.png",
      link: "https://indianexpress.com/article/world/diversity-key-to-resilience-and-success-canadian-pm-justin-trudeau-5076592/",
      id: 11,
      class: 'media-article-single-container' 
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-07_vq2f8t.png",
      link: "https://www.catchnews.com/international-news/don-t-define-yourself-by-religion-or-caste-but-by-your-passion-justin-trudeau-to-india-s-youth-100617.html",
      id: 12,
      class: 'end-med media-article-single-container' 
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052608/cards/news_articles/NEWS_ARTICAL-12_jjzmbz.png",
      link: "https://www.dnaindia.com/mumbai/interview-youth-conclave-to-show-the-e-way-samyak-chakrabarty-2075962",
      id: 13,
      class: 'starting-med media-article-single-container' 
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-03_jamoni.png",
      link: "",
      id: 14,
      class: 'media-article-single-container' 
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-01_ilk1yu.png",
      link: "http://everythingexperiential.businessworld.in/article/UNYCC-is-a-great-opportunity-to-connect-with-youth-Athang-Jain/15-10-2018-162309/",
      id: 15,
      class: 'end-med media-article-single-container' 
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052607/cards/news_articles/NEWS_ARTICAL-02_ha7inh.png",
      link: "https://www.hindustantimes.com/bollywood/dia-mirza-on-meeting-justin-trudeau-he-was-so-grounded-and-simple-such-an-earnest-and-honest-person/story-jjBDJhhqSrdH3yJBuvsJ0H.html",
      id: 16,
      class: 'starting-med media-article-single-container' 
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642052608/cards/news_articles/NEWS_ARTICAL-17_m6ydmk.png",
      link: "",
      id: 17,
      class: 'media-article-single-container' 
    }
];


  export default mediaList;