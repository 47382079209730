import Team2 from "../../../assets/team/team2.png";

const Advisory = [
    {
      name: "Rajiv Chandran",
      designation: "National Information Officer, United Nations Centre for India & Bhutan",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642072512/cards/advisory_board/Asset_11_xofowe.png",
    },
    {
      name: "Poonam Mahajan",
      designation: "Member of Parliament ",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642072426/cards/advisory_board/Asset_10_c7apc8.png",
    },
  ];


  export default Advisory;