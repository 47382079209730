import UnyccLogo from "../../../assets/7_SUPER_POWER-01.png"; 

function UnyccSecond() {
    return (
      <div className="unycc-second-section-container">
          <div className="unycc-second-logo-container"> 
          <div className="unycc-second-logo-position">
            <img 
                src={UnyccLogo} 
                alt="unycc 2022 new logo"
                className="unycc-logo-unycc-section" 
            />
          </div>
          </div>
          
          <div className="unycc-second-text-container">
              <div className="unycc-text-wrapper">
                <div>
                    <h1>About the event</h1>
                    <hr className="title-divider-unycc" />
                </div>
                <div>
                    <p>
                        Factors like climate change, overpopulation, professional redundancy caused by automation and rising inequality will cause significant negative impact on humanity. It is therefore imperative to have a dialogue on how emerging  technologies, progressive policy and behavior change can be leveraged to secure a quality life for future generations.
                    </p>

                    <p>
                        7 superpowers can balance the negative impact on nature and humanity if enabled. UNYCC 2022 will explore what these are and how they can be leveraged. 
                    </p>
                </div>
              </div>
          </div>
      </div>
    );
  }
  
  export default UnyccSecond;
  