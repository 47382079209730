import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// Import Photos
import PastSpeakerImg1 from '../../../assets/past-speakers/past-speaker1.png';
import pastSpeakersList from "./pasSpeakersList";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

function PastSpeakersSecondSection() {
    return (
        <div>
            <div className='pastspeaker-second-section-container'>
            <Box 
                sx={{ flexGrow: 1 }}
                className="past-speaker-grid-container"    
            >
                <Grid 
                    container 
                    spacing={0}
                    className="past-speaker-img-all-container"
                >
                    {pastSpeakersList.map(speaker => (
                        <Grid 
                            item 
                            lg={3}
                            md={4}
                            xs={6}
                            className="past-speaker-img-container-single"
                        >
                        <img 
                            src={speaker.img} 
                            alt="past speaker photos" 
                            className="past-speakers-photos-img"    
                        />
                        </Grid> 
                    ))}
                </Grid>
            </Box>
            </div>
        </div>
    )
}

export default PastSpeakersSecondSection








// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// {/* 2nd Row */}
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// {/* 3rd Row */}
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// {/* 4th Row */}
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
// <Grid 
// item 
// lg={3}
// md={4}
// xs={6}
// className="past-speaker-img-container-single"
// >
// <img 
//     src={PastSpeakerImg1} 
//     alt="past speaker photos" 
//     className="past-speakers-photos-img"    
// />
// </Grid>
