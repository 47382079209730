import Cards from "./Cards.js";
import FloatingData from "./FloatingData.js";
import ImageGallery from "./ImageGallery.js";
import HomepageScroll from "./HomepageScroll.js";


function BodyTop() {


  return (
    <div className="rel-container-header-top">
        <div className="body-img-container">
            <div className="heading-top heading-top-mobile-homepage home-banner-below">
              <div className="heading-top-text-container">
                <h1 className="heading-text">taking inspiration to the far<br/> corners of india</h1>
                <h1 className="heading-back">taking inspiration to the far<br/> corners of india</h1> 
              </div>
            </div>
            <div className="homepage-scrol-container">
              <HomepageScroll />
            </div>
            
            {/* <Cards /> */}
           
            <FloatingData /> 
                 
        </div>
 
        
    </div>
  );
}

export default BodyTop;
