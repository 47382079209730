import Team1 from "../../../assets/team/team1.png";

const pastSpeakersList = [
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_9_wttl1a.png",
      id: 1     
    },  
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_10_hpi67u.png",
      id: 2
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991945/cards/past_speakers/Asset_11_th05qo.png",
      id: 3
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991942/cards/past_speakers/Asset_12_m1vcys.png",
      id: 4
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991944/cards/past_speakers/Asset_13_ww9xob.png",
      id: 5
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991944/cards/past_speakers/Asset_14_qqkut8.png",
      id: 6
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991942/cards/past_speakers/Asset_15_djaunj.png",
      id: 7
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991944/cards/past_speakers/Asset_16_wa2okm.png",
      id: 8
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991942/cards/past_speakers/Asset_17_uldlb1.png",
      id: 9
    },  
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991944/cards/past_speakers/Asset_18_gslv0q.png",
      id: 10
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991944/cards/past_speakers/Asset_19_kditgv.png",
      id: 11
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_35_hgn1yb.png",
      id: 12
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991942/cards/past_speakers/Asset_21_yy5iwd.png",
      id: 13
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991942/cards/past_speakers/Asset_22_tlhdst.png",
      id: 14
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991944/cards/past_speakers/Asset_23_rkn9oq.png",
      id: 15
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991942/cards/past_speakers/Asset_24_ctoxmt.png",
      id: 16
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991945/cards/past_speakers/Asset_25_pkdwyc.png",
      id: 17
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991944/cards/past_speakers/Asset_26_qemltx.png",
      id: 18
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991944/cards/past_speakers/Asset_27_i348yv.png",
      id: 19
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_28_boith9.png",
      id: 20
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_29_cqygaj.png",
      id: 21
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_30_qtmbfu.png",
      id: 22
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991945/cards/past_speakers/Asset_31_klyz3v.png",
      id: 23
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_32_emw4fe.png",
      id: 24
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_33_tgimss.png",
      id: 25
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_34_zglgjr.png",
      id: 26
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991943/cards/past_speakers/Asset_35_hgn1yb.png",
      id: 27
    },
    {
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1641991945/cards/past_speakers/Asset_36_wa5si6.png",
      id: 28
    }
];


  export default pastSpeakersList;