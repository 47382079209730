import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import listofcities from '../register/listofcities';
import listofstates from '../register/listofstates';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Success from '../register/Success';
import listofcountries from "../register/listofcontries";
import axios from "axios";
import Server_Link from '../../serverLink';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

const RegisterationFormMain = () => {
    
    const superPowerNameList = ['Green Tech' , 'Mindfulness', 'Healthcare Tech', 'Woman In Power', 'Human Intelligence', 'Conscious Capitalism', 'Population Control']
    const unyccCityNameList = ['UNYCC MUMBAI', 'UNYCC SHILLONG (Coming Soon)', 'UNYCC DELHI (Coming Soong)', 'UNYCC GUJARAT (Coming Soong)'];

    const [open, setOpen] = React.useState(false);

   

  
    const [dialogueOpen, setDialogueOpen] = React.useState(false);

    const handleDialogueClickOpen = () => {
        setDialogueOpen(true);
    };

    const handleDialogueClose = () => {
        setDialogueOpen(false);
    };
    
    //Open just form clicked
    const [sending, setSending] = React.useState(false);

      // For sending and showing errors
      const [errorName, setErrorName] = React.useState(false);
      const [errorInfo, setErrorInfo] = React.useState(false);

    // Unycc place select
    const [unyccPlace, setUnyccPlace] = React.useState('');
    // Firt Name
    const [firstName, setFirstName] = React.useState('');
    // Last Name
    const [lastName, setlastName] = React.useState('');
    // Email ID
    const [email, setEmail] = React.useState('');
    // Phone no.
    const [phone, setPhone] = React.useState('');
    // Gender
    const [gender, setGender] = React.useState('');
    // Age
    const [age, setAge] = React.useState('');
    // City
    const [city, setCity] = React.useState('');
    // State
    const [states, setStates] = React.useState('');
    // Institution Name
    const [institution, setInstitution] = React.useState('');
    // Course Name
    const [course, setCourseName] = React.useState('');
    // Course Name
    const [nationality, setNationality] = React.useState('');
    // Select Superpower
    const [superPower, setSuperPower] = React.useState('');
    // Reason (why)
    const [why, setWhy] = React.useState('');

    

    // Send dropdown data states
    const [citySend, setCitySend] = React.useState(''); 
    const [stateSend, setStateSend] = React.useState(''); 
    const [superPowerSend, setSuperPowerSend] = React.useState('');
    const [unyccCitySend, setUnyccCitySend] = React.useState('');
    const [national, setNational] = React.useState('')

    const [rsvpStatus, setRsvpStatus] = React.useState('');

    const handleRsvpStatusChange = (event) => {
        setRsvpStatus(event.target.value);
    };



    const handleUnyccPlace = (event) => {
        const unyccCityNumber = event.target.value - 1;
        const unyccCityName = unyccCityNameList[unyccCityNumber];
        setUnyccCitySend(unyccCityName);
        setUnyccPlace(event.target.value);
    };
    const handleCityChange = (event) => {
        const cityNumber = event.target.value;
        const cityName = listofcities[cityNumber];
        setCitySend(cityName);
        setCity(cityNumber);
    };

    const handleNationalityChange = (e) => {
        const countryNumber = e.target.value;
        const countryName = listofcountries[countryNumber];
        setNational(countryNumber)
        setNationality(countryName);
    }


    const handleStateChange = (event) => {
        const stateNumber = event.target.value;
        const stateName = listofstates[stateNumber];
        setStateSend(stateName);
        setStates(stateNumber);
    };
    const handleSuperPower = (event) => {
        const superPowerNumber = event.target.value - 1;
        const superPowerName = superPowerNameList[superPowerNumber]
        setSuperPowerSend(superPowerName)
        setSuperPower(event.target.value);
    };

    const handleFirstNameChange = (e) => {
        const firstName = (e.target.value).toLowerCase();
        setFirstName(firstName);
    }
    const handleLastNameChange = (e) => {
        const lastName = (e.target.value).toLowerCase();
        setlastName(lastName);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    }

    // const handleInstituteChange = (e) => {
    //     setInstitution(e.target.value);
    // }

   
    // const handleCourseChange = (e) => {
    //     setCourseName(e.target.value);
    // }

    // const handleAgeChange = (e) => {
    //     setAge(e.target.value);
    // }
    
    // const handleWhyChange = (e) => {
    //     setWhy(e.target.value);
    // }
    
    // const handleGenderChange = (e) => {
    //     setGender(e.target.value)
    // }

    const handleFormSubmit = (e) => {
       
       
        if(firstName && lastName && email && rsvpStatus)
        {

            setSending(true);
            const application = {
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "rsvpStatus": rsvpStatus,
            }

            axios.post(`${Server_Link}/api/rsvpcheck`, application)
            .then(response => {
                console.log(response.data)
                if(response.data.code == 11000) {
                    alert("You have already submitted with this email")
                    setOpen(false)
                    setSending(false)
                }else {
                    setOpen(true)
                    setDialogueOpen(true);
                    setSending(false)
                    setFirstName('');
                    setlastName('');
                    setEmail('');
                    setPhone('');
                    setGender('');
                    setAge('');
                    setCity('');
                    setInstitution('');
                    setCourseName('');
                    setNationality('');
                }
            })
            .catch(error => {
                setOpen(false)
                
                alert(`There was an error please contact the developer: 
anuj@xbillionskillslab.com`)
                console.log(error)
                console.log(error.message)
                // console.log(error.message);
        });
        } else {
            alert("Please fill all the fields");
        }
        
        e.preventDefault();
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSending(false);
        setOpen(false);
      };


    return (
        <div>
            <Dialog
                open={dialogueOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogueClose}
                aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle>{"Success"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                Thank you for confirming!
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            {/* <Button onClick={handleDialogueClose}>Disagree</Button> */}
            <Button onClick={handleDialogueClose}>OK</Button>
            </DialogActions>
        </Dialog>
             <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Submitted
                        </Alert>
                    </Snackbar>
                </Stack>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={sending} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                            Sending...
                        </Alert>
                    </Snackbar>
                </Stack>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={errorName} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            {errorInfo}
                        </Alert>
                    </Snackbar>
            </Stack>
        <div className='registeration-form-form-rsvp doublersvp-page'>
            <div className='form-container-register'>
                <form>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        </Grid>

                        {/* <Grid item xs={12}>
                            <Typography 
                                        variant="h6" 
                                        component="h6"
                                        color="#fff"
                                        sx={{
                                            margin: '10px 0' 
                                        }}
                                        className="event-info-form-submit-page"
                            >
                                Date : <span className='event-info-form-text event-info-text-point'>April 9th</span><br/>
                                Time : <span className='event-info-form-text event-info-text-point'>5pm to 9pm</span><br />
                                Venue : <span className='event-info-form-text event-info-text-point'>Naval Lawns, Colaba</span>
                            </Typography>
                        </Grid> */}

                        {/* <Grid item xs={12}>
                            <Typography 
                                    variant="h6" 
                                    component="h6"
                                    color="#fff"
                                    sx={{
                                        margin: '10px 0' 
                                    }}
                                    className="please-note-text-rsvp-page"
                            >
                                Please note :
                            </Typography>

                            <Typography 
                                variant="h6" 
                                component="h6"
                                color="#fff"
                                sx={{
                                    margin: '10px 0' 
                                }}
                                
                            >
                               <ol className='event-info-form-text event-info-text-point'>
                                    <li>Your details are secure with us.</li>
                                    <li>This information will only be used for ensuring a seamless security protocol at entry.</li>
                                    <li>There is no fee for registeration</li>
                                </ol>
                                
                            </Typography>

                            
                        </Grid> */}

                        {/* <Grid item xs={12}>
                            <FormControl fullWidth>
                                    <Typography 
                                        variant="h6" 
                                        component="h6"
                                        color="#fff"
                                        sx={{
                                            margin: '10px 0' 
                                        }}
                                    >Which conclave would you like to attend?</Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={unyccPlace}
                                        onChange={handleUnyccPlace}
                                        className='register-form-control'
                                    >
                                        <MenuItem value={1}>UNYCC MUMBAI</MenuItem>
                                        <MenuItem value={2} disabled>UNYCC SHILLONG (Coming Soon)</MenuItem>
                                        <MenuItem value={3} disabled>UNYCC DELHI (Coming Soong)</MenuItem>
                                        <MenuItem value={4} disabled>UNYCC GUJARAT (Coming Soong)</MenuItem>
                                    </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={6}>
                            <Typography 
                                    variant="h6" 
                                    component="h6"
                                    color="#fff"
                                    sx={{
                                        margin: '10px 0' 
                                    }}
                            >
                                First Name
                            </Typography>

                            <TextField 
                                id="outlined-basic" 
                                variant="outlined" 
                                fullWidth
                                className='register-form-control'
                                onChange={handleFirstNameChange}
                                value={firstName}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography 
                                        variant="h6" 
                                        component="h6"
                                        color="#fff"
                                        sx={{
                                            margin: '10px 0' 
                                        }}
                                >
                                    Last Name
                            </Typography>

                            <TextField 
                                id="outlined-basic" 
                                variant="outlined" 
                                fullWidth
                                className='register-form-control'
                                onChange={handleLastNameChange}
                                value={lastName}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography 
                                variant="h6" 
                                component="h6"
                                    color="#fff"
                                    sx={{
                                        margin: '10px 0' 
                                    }}
                            >
                                Email
                            </Typography>

                            <TextField 
                                id="outlined-basic" 
                                variant="outlined" 
                                fullWidth
                                className='register-form-control'
                                onChange={handleEmailChange}
                                value={email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography 
                                variant="h6" 
                                component="h6"
                                    color="#fff"
                                    sx={{
                                        margin: '10px 0' 
                                    }}
                            >
                                Will you be attending?
                            </Typography>
                            <FormControl className='radio-button-rsvp'>
                                {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={rsvpStatus}
                                    onChange={handleRsvpStatusChange}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                                </FormControl>
                        </Grid>


                        {/* <Grid item xs={12}>
                        <Typography 
                            variant="h6" 
                                component="h6"
                                color="#fff"
                                    sx={{
                                        margin: '10px 0' 
                                }}
                        >
                                    Age
                            </Typography>     
                            <TextField 
                                id="outlined-basic"     
                                variant="outlined" 
                                fullWidth
                                className='register-form-control'
                                onChange={handleAgeChange}
                                value={age}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12}>
                        <FormControl 
                            component="fieldset"
                        >
                            <FormLabel component="legend">
                                <Typography 
                                        variant="h6" 
                                        component="h6"
                                            color="#fff"
                                            sx={{
                                                margin: '10px 0' 
                                            }}
                                    >
                                        Gender
                                </Typography>     
                            </FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="radio-buttons-group"
                            >   
                                <div className='radio-form-control'>
                                    <FormControlLabel onChange={handleGenderChange} value="female" control={<Radio />} label="Female" />
                                    <FormControlLabel onChange={handleGenderChange} value="male" control={<Radio />} label="Male" />
                                </div>
                            </RadioGroup>
                        </FormControl>   
                        </Grid> */}
                        {/* <Grid item xs={12}>
                            <FormControl fullWidth>
                                        <Typography 
                                            variant="h6" 
                                            component="h6"
                                            color="#fff"
                                            sx={{
                                                margin: '10px 0' 
                                            }}
                                        >City</Typography>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={city}
                                            onChange={handleCityChange}
                                            className='register-form-control'
                                        >
                                            {listofcities.map((city, index) => (
                                                <MenuItem value={index}>{city}</MenuItem>
                                            ))}
                                        </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                        <FormControl fullWidth>
                                        <Typography 
                                            variant="h6" 
                                            component="h6"
                                            color="#fff"
                                            sx={{
                                                margin: '10px 0' 
                                            }}
                                        >State</Typography>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={states}
                                            className='register-form-control'
                                            onChange={handleStateChange}
                                        >
                                            {listofstates.map((state, index) => (
                                                <MenuItem value={index}>{state}</MenuItem>
                                            ))}
                                            
                                        </Select>
                        </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                                <Typography 
                                        variant="h6" 
                                        component="h6"
                                        color="#fff"
                                        sx={{
                                            margin: '10px 0' 
                                        }}
                                >
                                    Institution/Organization
                                </Typography>

                                <TextField 
                                    id="outlined-basic" 
                                    variant="outlined" 
                                    fullWidth
                                    className='register-form-control'
                                    onChange={handleInstituteChange}
                                    value={institution}
                                />
                        </Grid> */}
                        {/* <Grid item xs={12}>         
                            <Typography 
                                variant="h6" 
                                component="h6"
                                color="#fff"
                                sx={{
                                    margin: '10px 0' 
                                }}
                            >
                                Course/Designation
                            </Typography>

                            <TextField 
                                id="outlined-basic" 
                                variant="outlined" 
                                fullWidth
                                className='register-form-control'
                                onChange={handleCourseChange}
                                value={course}
                            />
                        </Grid> */}
                            
                        {/* <Grid item xs={12}>
                            <FormControl fullWidth>
                                        <Typography 
                                            variant="h6" 
                                            component="h6"
                                            color="#fff"
                                            sx={{
                                                margin: '10px 0' 
                                            }}
                                        >Nationality</Typography>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={national}
                                            onChange={handleNationalityChange}
                                            className='register-form-control'
                                        >
                                            {listofcountries.map((country, index) => (
                                                <MenuItem value={index}>{country}</MenuItem>
                                            ))}
                                        </Select>
                            </FormControl>
                        </Grid> */}

                        {/* <Grid item xs={12}>

                            <FormControl fullWidth>
                                        <Typography 
                                            variant="h6" 
                                            component="h6"
                                            color="#fff"
                                            sx={{
                                                margin: '10px 0' 
                                            }}

                                        >
                                            Which superpower do you think is most important for the world today?
                                        </Typography>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={superPower}
                                            onChange={handleSuperPower}
                                            className='register-form-control'
                                        >
                                            <MenuItem value={1}>Green Tech</MenuItem>
                                            <MenuItem value={2}>Mindfulness</MenuItem>
                                            <MenuItem value={3}>Healthcare Tech</MenuItem>
                                            <MenuItem value={4}>Woman In Power</MenuItem>
                                            <MenuItem value={5}>Human Intelligence</MenuItem>
                                            <MenuItem value={6}>Conscious Capitalism</MenuItem>
                                            <MenuItem value={7}>Population Control</MenuItem>
                                        </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                            <Typography 
                                variant="h6" 
                                component="h6"
                                color="#fff"
                                sx={{
                                    margin: '10px 0' 
                                    }}
                            >
                                Why?
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                defaultValue=""
                                className="register-form-control"
                                onChange={handleWhyChange}
                                value={why}
                                fullWidth
                            />
                        </Grid> */}
                        <Grid 
                            item 
                            xs={12} 
                            className="submit-button-rsvp"
                        >
                            <Button 
                                variant="contained"
                                className='homepage-second-section-button red-to-blue register-btn contact-form-button-main'
                                type="submit"
                                onClick={handleFormSubmit}
                            >
                               {sending ? <CircularProgress color="secondary" size={20} /> : 'SUBMIT'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                </form>
            </div>
        </div>
        </div>
    )
}

export default RegisterationFormMain;




