

function AboutTeamSection(props) {

  return (
    <div className="photo-container-team">
        <div>
            <div className="img-container-class-center">
              <img 
                  src={props.img} 
                  alt="team" 
                  className="teamphoto align-photo-center"
              />
            </div>
            <div className="designation-name-container">
              <div>
                <h4>{props.name}</h4>
                <p>{props.designation}</p>
              </div>
            </div>
        </div>
    </div>
  );
}

export default AboutTeamSection;
