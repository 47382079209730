import React, {useEffect} from "react";
import {AnimatePresence, motion} from "framer-motion";
import BodyTop from './homepage/HomepageTop.js';
import HomepageSecondSection from "./homepage/HomepageSecondSection.js";
import HomepageMissionSection from "./homepage/HomepageMissionSection.js";
import HomepageContactSection from "./homepage/HomepageContactSection.js";
import HompageBanner from "./homepage/HompageBanner.js";
import '../../styles/homepage.css';
import '../../styles/homepage-responsive.css';


const pageVarients = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  }
}

const pageTransition = {
  type: "tween",
  ease: "easeOut",
  duration: 0.5
}



function Homepage() {
  
 

  return (
    <motion.div  
      initial="out"  
      animate="in"
      exit="out"
      variants={pageVarients}
      transition={pageTransition}
    >   
        <HompageBanner />
        <BodyTop  />
        <HomepageSecondSection />
        <HomepageMissionSection/>
        <HomepageContactSection  />
    </motion.div>
  );
}

export default Homepage;
