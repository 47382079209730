import React, {useState} from "react";
import "../../../styles/form.css";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import validator from "email-validator";
import { FaCreativeCommonsPd } from "react-icons/fa";
import Server_Link from "../../serverLink";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  


function HomepageContactForm(){
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    
    //Open Successful Submited Form
    const [open, setOpen] = useState(false);

    // For sending and showing errors
    const [errorName, setErrorName] = useState(false);
    const [errorInfo, setErrorInfo] = useState(false);

    //Open just form clicked
    const [sending, setSending] = useState(false);

  
    //Perform validation here
    function handleNameChange(e){
        setName(e.target.value);
    }

    function handleEmailChange(e){  
        setEmail(e.target.value);
    }

    function handleMessageChange(e){
        setMessage(e.target.value);
    }

    function handleFormSubmit(e){
        e.preventDefault();
        setSending(true)

        //if someone submitted form before than making it false
        
        if(open === true) {
            setOpen(false);
        }

        if(errorName === true) {
            setErrorName(false);
        }
        


      
         
        // My custom email validator  
        
        let emailTrue = validator.validate(email);

        if(name.length <= 2){
            setErrorInfo("Please Enter Correct Name");
            setSending(false)
            setErrorName(true)
        } else if (!emailTrue){
            setErrorInfo("Please Enter Correct Email")
            setSending(false)
            setErrorName(true)
        } else if (message.length <= 3){
            setErrorInfo("Please Enter Message");
            setSending(false)
            setErrorName(true)
        }else{
            let contactData = {
                'name': name,
                'email': email,
                'message': message
            }
    
        
            
            axios.post(`${Server_Link}/api/contact`, contactData)
            .then(response => {
                setOpen(true)
                setSending(false)
                setName('')
                setEmail('')
                setMessage('')

            })
            .catch(error => {
                console.log(error);
                console.log(error.message);
        });
        }

        
        

    }

    // const handleClick = () => {
    //   setOpen(true);
    // };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

return (
    <div>
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Submitted, we will get in touch with you!
                </Alert>
            </Snackbar>
        </Stack>
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={sending} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                    Sending...
                </Alert>
            </Snackbar>
        </Stack>
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={errorName} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorInfo}
                </Alert>
            </Snackbar>
        </Stack>
    <div className="homepage-contact-flexbox">
        <div className="homepage-contact-container-form">
            <div className="homepage-contact-heading">
                <h2 className="homepage-contact-title">Get in touch</h2>
                <hr className="hr-homepage-contact" />
            </div>
            <div>
                <form>
                    <div className="input-container">
                        <label htmlFor="name">Name</label>
                            <input 
                                type="text"    
                                id="formikName" 
                                className="form-control"  
                                required
                                value={name}
                                onChange={handleNameChange}
                            />
                          
                    </div>
                    <div className="input-container">
                        <label htmlFor="email">Email</label>
                            <input 
                                type="email" 
                                id="formikEmail" 
                                className="form-control" 
                                required
                                value={email}
                                onChange={handleEmailChange}
                            />
                    </div>
                    <div className="input-container">
                        <label htmlFor="message">Message</label>
                            <textarea 
                                id="formikMessage" 
                                className="form-control" 
                                rows="4" 
                                cols="50"
                                value={message}
                                onChange={handleMessageChange}    
                            >
                            </textarea>
                    </div>
                    <div className="input-container btn-mobile-container">
                        <Button 
                            variant="outlined" 
                            className="homepage-second-section-button red-to-blue contact-form-button-main"
                            onClick={handleFormSubmit}
                            type="submit"
                        >
                        {sending ? <CircularProgress color="secondary" size={20} /> : 'SUBMIT'}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </div>
);}

export default HomepageContactForm;
   