import VenueCity from "../../../assets/event-info/city.png";
import VenueDate from "../../../assets/event-info/date.png";
import VenueLocation from "../../../assets/event-info/loc.png";
import NaveliLogo from "../../../assets/logos/naveli.png";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

function UnyccTop() {
  return (
    <div id="top-page" >
        <div className="unycctop-main-container">
            <div className="heading-top hidden-on-mobilephone city-top-text">
                <div className="heading-top-text-container about-title-text ">
                    <h1 className="heading-text city-text-top-size">UN YOUNG CHANGEMAKERS CONCLAVE 2022</h1>
                    <h1 className="heading-back city-text-top-size">UN YOUNG CHANGEMAKERS CONCLAVE 2022</h1> 
                </div>
            </div>
            <div className="heading-top heading-top-unycc hidden-on-pc">
                <div className="heading-top-text-container  heading about-title-text">
                    <h1 className="heading-text city-text-top-size">UN YOUNG CHANGEMAKERS <br />CONCLAVE 2022</h1>
                    <h1 className="heading-back city-text-top-size">UN YOUNG CHANGEMAKERS <br />CONCLAVE 2022</h1> 
                </div>
            </div>
            <div className="location-and-logo-holder">
                <div className="container-logo-relative">
                    <div>
                    <img 
                        src={VenueCity} 
                        alt="venue-detail" 
                        className="venue-detail-unycc venue-mumbai"
                    />
                    <img 
                        src={VenueLocation} 
                        alt="venue-detail" 
                        className="venue-detail-unycc venue-date"
                    />
                    <img 
                        src={VenueDate} 
                        alt="venue-detail" 
                        className="venue-detail-unycc venue-naval"
                    />
                    </div>
                    <img 
                        src={NaveliLogo} 
                        alt="Project Naveli Logo"
                        className="venue-detail-unycc project-naveli-logo"
                    />
                </div>
            </div>
              
        </div>
    </div>
  );
}

export default UnyccTop;
