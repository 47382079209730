
import React, {useEffect} from "react";
import Card1 from "../../../assets/cards/card1.png";
import Card2 from "../../../assets/cards/card2.png";
import Card3 from "../../../assets/cards/card3.png";
import Card4 from "../../../assets/cards/card4.png";
import Card6 from "../../../assets/cards/card6.png";
import Card7 from "../../../assets/cards/card7.png";


function AboutSecondSection() {
  
    return (
      <div>
        <div className="aboutpage-second-container">
            <div className="aboutpage-flex-container left-container">
              <div className="aboutpage-second-width">
                
                <div className="aboutsectionsecond-top-title">
                  <h1>About UNYCC</h1>
                  <hr className="title-divider title-divider-about-section"/>
                </div>
                <div className="aboutsectionsecond-text">
                  <p>
                    The Young Changemakers Conclave (YCC) was founded in 2011 by X-Billion Skills Lab and United Nations India Centre. YCC's core purpose was to create a platform for students and young entrepreneurs to engage with leaders from the domains of politics, business, media, the arts, technology, sports and entertainment. The Goal then was to seed perspectives, ideas and missions in the minds of our nation’s future. With time, the platform’s purpose and target has evolved.
                  </p>

                  <p>
                    Our aim now is to raise awareness amongst citizens about how factors such as emerging technology, epidemics, artificial intelligence, climate change, overpopulation and automation will affect their quality of life. More specifically, we will adapt and contextualize our content to connect with rural & semi urban populations which constitute the majority of our nation’s demographic.
                  </p>
                </div>
              </div>
            </div>
            <div className="aboutpage-flex-container right-container">
              <div className="aboutpage-img">
                <div className="image-first-section">
                  <div className="about-margin-second about-second-img1">
                      <img 
                        src="https://res.cloudinary.com/anujnirmal/image/upload/v1641990920/cards/square%20cards/Asset_1_slj1te.png" 
                        alt="speaker-photos"
                        className="aboutpage-second-section-images" 
                      />
                  </div>
                  <div className="about-margin-second about-second-img2">
                      <img 
                        src="https://res.cloudinary.com/anujnirmal/image/upload/v1641990921/cards/square%20cards/Asset_5_mxjejg.png" 
                        alt="speaker-photos"
                        className="aboutpage-second-section-images" 
                      />
                  </div>
                </div>
                <div className="image-second-section">
                  <div className="about-margin-second about-second-img3">
                      <img 
                        src="https://res.cloudinary.com/anujnirmal/image/upload/v1641990920/cards/square%20cards/Asset_4_cnpn4t.png" 
                        alt="speaker-photos"
                        className="aboutpage-second-section-images" 
                      />
                  </div>
                  <div className="about-margin-second about-second-img4">
                      <img 
                        src="https://res.cloudinary.com/anujnirmal/image/upload/v1641990920/cards/square%20cards/Asset_3_ofglhf.png" 
                        alt="speaker-photos"
                        className="aboutpage-second-section-images"
                      />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default AboutSecondSection;
  