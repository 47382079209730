import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './components/App';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyAwa_wOr0ipLNtW4wB0X6wOQzS9JGcbzns",
  authDomain: "unycc2022.firebaseapp.com",
  projectId: "unycc2022",
  storageBucket: "unycc2022.appspot.com",
  messagingSenderId: "749792251182",
  appId: "1:749792251182:web:6e8cdd5665d9fa02bcfebf",
  measurementId: "G-HRV7BXXNLW"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
