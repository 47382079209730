import React from 'react';
import RegistrationTop from "./register/RegistrationTop";
import RegisterationFormMain from "./register/RegisterationFormMain";
import "../../styles/register-form.css";

const RegistrationForm = () => {
    return (
        <div>
            <RegistrationTop />
            <RegisterationFormMain />
        </div>
    )
}

export default RegistrationForm
