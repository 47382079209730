import React from 'react'

function MediaTop() {
    return (
        <div>
            <div className='media-top-container'>
                <div className="heading-top media-top-section">
                    <div className="heading-top-pos">
                        <div className="heading-top-text-container">
                            <h1 className="heading-text">MEDIA</h1>
                            <h1 className="heading-back">MEDIA</h1> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MediaTop
