import React, {useState} from "react";
import * as ReactDOM from "react-dom";
import {BrowserRouter as Router,Routes, Route, Link } from "react-router-dom";
import '../styles/homepage.css';
import Homepage from './pages/Homepage.js';
import About from "./pages/About.js";
import Unycc from "./pages/Unycc.js";
import Cities from "./pages/Cities.js";
import PastSpeakers from "./pages/PastSpeakers";
import Media from "./pages/Media";
import Navbar from "./Navbar.js";
import ScrollToTop from "./ScrollToTop";
import Footer from "./Footer";
import RegisterForm from "./pages/RegistrationForm";
import Img from "./img";
import Uploads from "./pages/uploads/Uploads";
import Rsvp from "./pages/rsvp/Rsvp";
import Aiesec from "./pages/rsvp/Aiesec";
import LeapClub from "./pages/rsvp/LeapClub";
import GlobalShapers from "./pages/rsvp/GlobalShapers";
import Bbg from "./pages/rsvp/Bbg";
import Weft from "./pages/rsvp/Weft";
import Schbang from "./pages/rsvp/Schbang";
import Wadhwani from "./pages/rsvp/Wadhwani";
import StrangerSons from "./pages/rsvp/StrangerSons";
import Yng from "./pages/rsvp/Yng";
import DoubleRsvp from "./pages/rsvp/DoubleRsvp"

function Header(){


  return (
    <div>   
          <ScrollToTop />
          <Navbar />
            <Routes >
              <Route exact path="/" element={<Homepage />} />
              <Route path="/about" element={<About />} />
              <Route path="/unycc" element={<Unycc />} />
              <Route path="/cityUnycc" element={<Unycc />} />
              <Route path="/pastspeakers" element={<PastSpeakers />} />
              <Route path="/media" element={<Media />} />
              {/* <Route path="/img" element={<Img />} /> */}
              <Route path="/register" element={<RegisterForm />} />
              {/* RSVP LINKS */}
              {/* <Route path="/rsvp" element={<Rsvp />} />
              <Route path="/rsvpcheck" element={<DoubleRsvp />} />
              <Route path="/leapclub" element={<LeapClub />} />
              <Route path="/globalshapers" element={<GlobalShapers />} />
              <Route path="/bbg" element={<Bbg />} />
              <Route path="/aiesec" element={<Aiesec />} />
              <Route path="/weft" element={<Weft />} />
              <Route path="/schbang" element={<Schbang />} />
              <Route path="/wadhwani" element={<Wadhwani />} />
              <Route path="/ssg" element={<StrangerSons />} />
              <Route path="/yng" element={<Yng />} /> */}
              {/* Rsvp Links end here */}
              <Route path="/uploads/:id" element={<Uploads />} />
            </Routes>
            <Footer />
    </div>
   
  );
}

export default Header;
