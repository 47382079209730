const listofcountries = ['British',
'Argentinian',
'Australian',
'Bahamian',
'Belgian',
'Brazilian',
'Canadian',
'Chinese',
'Colombian',
'Cuban',
'Dominican',
'Ecuadorean',
'Salvadorean',
'French',
'German',
'Guatemalan',
'Haitian',
'Honduran',
'Indian',
'Ireland',
'Israeli',
'Italian',
'Japanese',
'South Korean',
'Mexican',
'Dutch',
'Philippine',
'Spanish',
'Swedish',
'Swiss',
'Taiwanese',
'Venezuelan',
'Vietnamese',
'Afghan',
'Albanian',
'Algerian',
'Samoan',
'Andorran',
'Angolan',
'Armenian',
'Austrian',
'Azerbaijani',
'Bahraini',
'Bangladeshi',
'Barbadian',
'Belarusian',
'Belizean',
'Beninese',
'Bermudian',
'Bhutanese',
'Bolivian',
'Bosnian',
'Botswanan',
'Bulgarian',
'Burkinese',
'Burundian',
'Cambodian',
'Cameroonian',
'Cape Verdean',
'Chadian',
'Chilean',
'Congolese',
'Costa Rican',
'Croat',
'Cypriot',
'Czech',
'Danish',
'Djiboutian',
'Dominican',
'Egyptian',
'Eritrean',
'Estonian',
'Ethiopian',
'Fijian',
'Finnish',
'Polynesian',
'Gabonese',
'Gambian',
'Georgian',
'Ghanaian',
'Greek',
'Grenadian',
'Guinean',
'Guyanese',
'Hungarian',
'Icelandic',
'Indonesian',
'Iranian',
'Iraqi',
'Jamaican',
'Jordanian',
'Kazakh',
'Kenyan',
'North Korean',
'Kuwaiti',
'Latvian',
'Lebanese',
'Liberian',
'Libyan',
'Lithuanian',
'LUXEMBOURG',
'Madagascan',
'Malawian',
'Malaysian',
'Maldivian',
'Malian',
'Maltese',
'Mauritanian',
'Mauritian',
'Monacan',
'Mongolian',
'Montenegrin',
'Moroccan',
'Mozambican',
'Namibian',
'Nepalese',
'New Zealand',
'Nicaraguan',
'Nigerien',
'Nigerian',
'Norwegian',
'Omani',
'Pakistani',
'Panamanian',
'Guinean',
'Paraguayan',
'Peruvian',
'Polish',
'Portuguese',
'Qatari',
'Romanian',
'Rwandan',
'Saudi Arabian',
'Senegalese',
'Serb or Serbian',
'Sierra Leonian',
'Singaporean',
'Slovak',
'Slovenian',
'Slomoni',
'Somali',
'South African',
'Sri Lankan',
'Sudanese',
'Surinamese',
'Swazi',
'Tajik',
'Thai',
'Togolese',
'Trinidadian',
'Tunisian',
'Turkish',
'Turkoman',
'Tuvaluan',
'Ugandan',
'Ukrainian',
'Emirati',
'American',
'Uruguayan',
'Uzbek',
'Vanuatuan',
'Yemeni',
'Zambian',
]

module.exports = listofcountries;