import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'; 
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import axios from 'axios';
import Server_Link from '../../serverLink';
import "./uploads.css";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Input = styled('input')({
  display: 'none',
});

const firebaseConfig = {
  apiKey: "AIzaSyAwa_wOr0ipLNtW4wB0X6wOQzS9JGcbzns",
  authDomain: "unycc2022.firebaseapp.com",
  projectId: "unycc2022",
  storageBucket: "unycc2022.appspot.com",
  messagingSenderId: "749792251182",
  appId: "1:749792251182:web:6e8cdd5665d9fa02bcfebf",
  measurementId: "G-HRV7BXXNLW"
};


firebase.initializeApp(firebaseConfig);
const storage = firebase.storage()




function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


const Uploads = ( props ) => {

  let params = useParams();
  let queryId = params.id;

  const [progress, setProgress] = React.useState(0);
  const [location, setLocation] = React.useState("");
  const [image, setImage] = React.useState();
  const [uploading, setUploading] = React.useState(false);
  const [aadharNumber, setAadharNumber] = React.useState('');

  const allInputs = {imgUrl: ''}
    const [imageAsFile, setImageAsFile] = React.useState('')
    const [imageAsUrl, setImageAsUrl] = React.useState(allInputs)

  React.useEffect(() => {
    // const timer = setInterval(() => {
    //   setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    // }, 800);
    // return () => {
    //   clearInterval(timer);
    // };
  }, []);

  function handleFileChange(e){
      const image = e.target.files[0];
      if(image.size >= 5000000){
        alert("File size should be below 5mb");
      }else {
     
        setLocation(image.name);
        setImageAsFile(imageFile => (image))
          // const smallFileName =  (imageAsFile.name).substr((imageAsFile.name).length - 70)
      }
 }

 function handleUpload(e){
  e.preventDefault()
  
  if(aadharNumber == "" || aadharNumber.length > 12){
    alert("Please Enter correct Aadhar Card number")
  }else if (imageAsFile.name == "") {
    alert("Please select your adhar card")
  }else{
    setUploading(true)
  const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
  uploadTask.on('state_changed', 
    (snapShot) => {
      //takes a snap shot of the process as it is happening
      let bytesTransferred = snapShot._delegate.bytesTransferred;
      let totalBytes = snapShot._delegate.totalBytes;
      
      let percentComplete = Math.round((bytesTransferred / totalBytes) * 100);
      setProgress(percentComplete);
    }, (err) => {
      //catches the errors
      console.log(err)
    }, () => {

      setUploading(false)
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      storage.ref('images').child(imageAsFile.name).getDownloadURL()
       .then(fireBaseUrl => {
        

         axios.post(`${Server_Link}/api/uploads/aadhar`, {
            imgId: queryId,
            aadhar: fireBaseUrl,
            aadharNumber: aadharNumber,
          })
          .then(function (response) {
            alert("Uploaded Successfully!")
          })
          .catch(function (error) {
            console.log(error);
          });

         setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
       })
    })
  }

  

  


  


}

function handleAadharCardNumber(e) {

  setAadharNumber(e.target.value)
}

  return (
    <div className='upload-section'> 
     <div  className="aboutpage-top-container">
          <div className="heading-top about-heading-top-section">
            <div className="heading-top-pos">
              <div className="heading-top-text-container">
                  <h1 className="heading-text">Upload</h1>
                  <h1 className="heading-back">Upload</h1> 
              </div>
            </div>
            </div>
      </div>
        <div className='upload-part'>

          <Grid container spacing={2} sx={{
            marginTop: '50px'
          }}>
            
            <Grid item xs={12}>
              <Typography variant='h5' sx={{
                textAlign: 'center',
                color: '#fff',
                fontFamily: 'Oxanium, Cursive'
              }}>Please upload your aadhar Card</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography 
                variant="h6" 
                component="h6"
                color="#fff"
                  sx={{
                    margin: '10px 0' 
                  }}
              >
                Aadhar Number
              </Typography>

              <TextField 
                id="outlined-basic" 
                variant="outlined" 
                fullWidth
                className='register-form-control'
                onChange={handleAadharCardNumber}
                required
              />
            </Grid>


            <Grid item xs={12} sx={{
              textAlign: 'center'
            }}>

              <label htmlFor="contained-button-file">
                    <Input 
                      accept="image/*" 
                      id="contained-button-file"  
                      type="file" 
                      onChange={handleFileChange}
                    
                    />
                    <Button 
                      variant="contained" 
                      component="span"
                      className='homepage-second-section-button red-to-blue register-btn contact-form-button-main'  
                    >
                      Select File
                    </Button>
                  </label>

            </Grid>


            <Grid item xs={12} sx={{
              textAlign: 'center'
            }}>

              <Button 
                      variant="contained" 
                      component="span"
                      className='homepage-second-section-button red-to-blue register-btn contact-form-button-main'  
                      sx={{
                       
                      }}
                      onClick={handleUpload}
                    >
                      {!uploading ? "Upload" :  (
                      <CircularProgress 
                        size={20}
                        sx={{
                          color: '#fff',
                        }}
                        
                      />) }
                    </Button>
            </Grid>
            
                  

                
            

            <Grid 
              item 
              xs={12}
              sx={{
                margin: '25px 0 -10px 0',
                overflowWrap: 'break-word'
              }}
            >
              <Typography 
                variant='p' 
                sx={{
                  color: '#fff',
                  wordWrap: 'wrap'
                  
                }}

              >{location}</Typography>
            </Grid>

            <Grid 
              item 
              xs={12}
              sx={{
                height: '10px'
              }}
            >

              <LinearProgressWithLabel 
                value={progress} 
                sx={{
                  height: '14px'
                }}  
              />

            </Grid>

            <Grid item xs={12} sx={{marginTop: '50px'}}>
              <Typography variant='p' 
                sx={{
                  color: '#fff'
                }}
              >Why are we asking for Aadhar Card?</Typography>
              <br/>
              <Typography 
                variant='p' 
                sx={{
                  color: '#fff'
                }}
              >The event will be held at Naval Lawns which is a highly secured Navy area, thus we will need your Aadhar Card.
              <ul>
                <li>It will enable us to give everyone fast entry</li>
                <li>It will ensure everyones security</li>
              </ul>
              </Typography>
            </Grid>
            
          </Grid>
     
        </div>
    </div>
)};

export default Uploads;






//   const file = e.target.files[0];


  //   const storage = getStorage();
  //   console.log(file.name)
  //   const storageRef = ref(storage, file.name);

  //   uploadBytes(storageRef, file).then((snapshot) => {
  //     console.log('Uploaded a blob or file!');
  //   });

  //   // const storageRef = app.storage().ref();
  //   // const fileRef = storageRef.child(file.name);
  //   // fileRef.put(file).then(() => {
  //   //     console.loh("Uploaded file" + file.name)
  //   // })
  //   //   console.log(file.name);
  // }

  // const upload = ()=>{
  //   if(image == null)
  //     return;
  //   storage.ref(`/images/${image.name}`).put(image)
  //   .on("state_changed" , alert("success") , alert);