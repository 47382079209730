import UnyccTop from "./unycc2022/UnyccTop.js";
import UnyccSecond from "./unycc2022/UnyccSecond.js";
import UnyccThird from "./unycc2022/UnyccThird.js";
import HomepageContactSection from "./homepage/HomepageContactSection";
import "../../styles/unycc.css";
import "../../styles/unycc-responsive.css";

function Unycc() {
  return (
    <div>
        <UnyccTop />
        <UnyccSecond />
        <UnyccThird />
        <HomepageContactSection />
    </div>
  );
}

export default Unycc;
