import Team1 from "../../../assets/team/team1.png";

const Teammembers = [
    {
      name: "Samyak Chakrabarty",
      designation: "President",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642072960/cards/team_photos/Asset_12_xda3o0.png",
      id: 1     
    },  
    {
      name: "Navya Naveli Nanda",
      designation: "Co-President",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642072960/cards/team_photos/Asset_13_cpmwmi.png",
      id: 2
    },
    {
      name: "Yashasvika Sharma",
      designation: "Vice President",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642145444/cards/team_photos/Asset_36_sdxmvz.png",
      id: 3
    },
    {
      name: "Minal B. Kapadia",
      designation: "Director - Operations",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642083484/cards/team_photos/Asset_28_ente8b.png",
      id: 4
    },
    {
      name: "Waridhi Joshi",
      designation: "Director - Event and logistics ",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642144309/cards/team_photos/Asset_33_g1illa.png",
      id: 5
    },
    {
      name: "Samaira Shah",
      designation: "Social Media & Communications",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642577968/cards/team_photos/saimara_cq7jo5.png",
      id: 6
    },
    {
      name: "Gaurav Jain",
      designation: "Director",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642086007/cards/team_photos/Asset_30_tgula6.png",
      id: 7
    },
    {
      name: "Drishti Joshi",
      designation: "Program Manager",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1646137991/cards/team_photos/drishtri_y65rvm.png",
      id: 8
    },
    {
      name: "Anuj Nirmal",
      designation: "Chargé D'Affaires",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642079307/cards/team_photos/Asset_24_erxrlv.png",
      id: 9
    },  
    {
      name: "Kabir Upreti",
      designation: "Content Production",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642078227/cards/team_photos/Asset_23_hpn269.png",
      id: 10
    },
    {
      name: "Vipul Lamba",
      designation: "Creative Designer",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1646137990/cards/team_photos/vipul_eyzzg4.png",
      id: 11
    },
    {
      name: "Ayan Pathak",
      designation: "Creative Designer",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1646137990/cards/team_photos/ayan_kleh7e.png",
      id: 12
    },
    {
      name: "Stuti Dholakia Buch",
      designation: "Member",
      img: "https://res.cloudinary.com/anujnirmal/image/upload/v1642082138/cards/team_photos/Asset_21_j272zk.png",
      id: 13
    }

  ];


  export default Teammembers;